import { useRef, useEffect, useState, React } from 'react'
import Image from 'next/image'
import Header from '../components/Header'
import Footer from '../components/Footer'
import PageTitle from '../components/Utils/PageTitle'
import SecureSVG from '../../public/secureLogo.svg'
import ResellSVG from '../../public/resellLogo.svg'
import InitialSaleSVG from '../../public/initialSale.svg'
import MarketplaceSVG from '../../public/marketplaceLogo.svg'
import MintSVG from '../../public/mintLogo.svg'
import BlockchainDB from '../../public/blockchainDbLogo.svg'
import ESC from '../../public/escLogo.svg'
import DFMA from '../../public/dfmaLogo.svg'

import IMG01 from '../../public/IMG01.png'
import IMG02 from '../../public/IMG02.webp'
import IMG03 from '../../public/IMG03.webp'
import DatabaseIcon from '../../public/database.svg'
import NativeIcon from '../../public/native.svg'
import AssetIcon from '../../public/asset.svg'
import JwBlockchain from '../../public/JwBlockchain.webp'
import SteveOlsonBeautifulExplotionCertPhone from '../../public/SteveOlsonBeautifulExplotionCertPhone.webp'
import DnaLogoWithoutText from '../../public/DnaLogoWithoutText.svg'

export default function LandingPage () {
  const navigationContainerRef = useRef(null)
  const learnSectionRef = useRef(null)
  const buildSectionRef = useRef(null)
  const supportSectionRef = useRef(null)
  const [navContainer, setNavContainer] = useState({ current: null })
  const [activeSection, setActiveSection] = useState(null)

  useEffect(() => {
    const { current: learnElement } = learnSectionRef
    // const { current: buildElement } = buildSectionRef
    const { current: supportElement } = supportSectionRef

    if (learnElement && supportElement) {
      const observer = new IntersectionObserver((entries) => {
        if (entries.length && entries[0].isIntersecting) {
          setActiveSection(entries[0].target.id)
        }
      }, { threshold: 0.2 })

      ;[learnElement, supportElement].forEach(e => {
        observer.observe(e)
      })
    }
  }, [learnSectionRef, supportSectionRef])

  useEffect(() => {
    if (navigationContainerRef) {
      setNavContainer(navigationContainerRef)
    }
  }, [navigationContainerRef])

  const navItems = {
    learn: learnSectionRef,
    support: supportSectionRef
  }

  return (
    <>
      <PageTitle title="Landing Page" />
      <div className="container-landing-mobile lg:container-landing mx-auto flex flex-col justify-between relative text-gray-700" ref={navigationContainerRef}>
        <Header navigationContainerRef={navContainer} isHome navItems={navItems} activeSection={activeSection} />
        <main>
          <section className=" bg-white z-0 pt-20 lg:pt-32 lg:w-full px-4" ref={learnSectionRef}>
            <div className="container-landing-mobile lg:container-landing flex flex-col lg:justify-between items-center lg:items-start lg:flex-row w-full">
              <div>
                <div className="text-left justify-between items-center lg:items-start lg:mt-28 lg:text-left font-semibold text-6xl lg:text-7xl text-black leading-tight tracking-tighter">
                Giving Objects  <br /> an Identity.
                </div>
                <div className='flex pt-6 w-full justify-center lg:justify-start lg:w-1/2'>
                  <a href="mailto:info@thedna.tech" className="text-xs text-center px-12 py-4 rounded-full bg-black text-white">Start now</a>
                </div>
              </div>
              <div className="w-11/12 lg:w-1/2 min-w-[60%] text-end pt-6 lg:pt-0 justify-end items-end text-right">
                <Image
                  src={IMG01}
                  alt="Main Asset"
                />
              </div>
            </div>
            <div className='text-[17px] justify-center text-justify w-11/12 lg:w-full mt-24 lg:mt-12'>
              <span className='font-medium text-4xl text-black leading-tight'>
                <div className='flex flex-row items-center justify-center'>
                  <div>
                    What is
                  </div>
                  <div className='mt-px ml-2 w-[100px] lg:w-[122px] h-auto md:w-[120px] mr-4 md:mr-0'>
                    <DnaLogoWithoutText height={25} width={122} viewBox={'0 0 100 16'}/>
                  </div>
                  <div>
                    ?
                  </div>
                </div>
              </span>
              <div className='flex flex-col lg:w-3/4 mx-auto justify-center mt-8 text-xl'>
                <div className='flex flex-row my-3 items-center'>
                  <div>
                    <DatabaseIcon className="" />
                  </div>
                  <div className='ml-6 text-left'>
                    <span className=' text-sky-800'> Database:</span> usually large collection of data organised for rapid search and retrieval.
                  </div>
                </div>
                <div className='flex flex-row my-3 items-center mt-5'>
                  <div className='-ml-4'>
                    <NativeIcon className="" />
                  </div>
                  <div className='ml-6 text-left'>
                    <span className=' text-sky-800'> Native:</span> grown, produced or originating in a particular place.
                  </div>
                </div>
                <div className='flex flex-row my-3 items-center'>
                  <div className='-ml-3'>
                    <AssetIcon className="" />
                  </div>
                  <div className='ml-6 text-left'>
                    <span className=' text-sky-800'> Asset:</span> item of value owned.
                  </div>
                </div>
              </div>
              <div className='lg:w-full mx-auto justify-center items-center text-center mt-8 lg:mt-6 text-3xl text-sky-800'>
                  The Web 3.0 platform for the Web 2.0 user base.
                </div>
            </div>
          </section>

          <section className=" bg-white z-0 lg:w-full">
            <div className="container-landing-mobile lg:container-landing flex flex-col justify-center items-center mt-16 lg:my-16">
              <div className="flex flex-row justify-center mt-2 w-full">
                <Image
                  src={IMG02}
                  alt="Web3.0.1"
                />
                <Image
                  src={IMG03}
                  alt="Web3.0.2"
                />
              </div>
            </div>
          </section>

          <section className=" bg-white z-0">
            <div className="flex flex-col justify-center items-center lg:flex-col mt-6 lg:mt-24 text-[17px]">
              <div className='flex flex-row items-center justify-center font-medium text-4xl text-black leading-tight'>
                <div>
                  What does
                </div>
                <div className='mt-px ml-2 w-[100px] lg:w-[122px] h-auto md:w-[120px] mr-4 md:mr-0'>
                  <DnaLogoWithoutText height={25} width={122} viewBox={'0 0 100 16'}/>
                </div>
                <div>
                  do?
                </div>
              </div>
              <div className='text-center mt-8 text-[17px] mb-8'>
                DNA is a Software as a Service (SaaS) Web3.0 platform for brands and producers to tokenise their physical goods
                Building safe, reliable, efficient and more connected physical marketplaces.
              </div>
              <div className="text-center flex flex-col lg:flex-row justify-center items-center lg:items-stretch text-[16px]">
                <div className='w-3/4 lg:w-full  rounded-2xl bg-gradient-to-b p-px mt-2 lg:m-2 from-[#0038FF] via-[#BD00FF] to-[#BD00FF]'>
                  <div className='"w-full h-full flex flex-col  items-center py-6 px-2 rounded-2xl bg-white'>
                    <div className=''>
                      <BlockchainDB className="" />
                    </div>
                    <div className='w-full mt-3'>
                      <span className='text-sky-800'>Blockchain as a Database</span>
                      <div className='mt-4'>
                        Digitise, archive, track and trade physical objects of any value as Native Assets (NFTs) on the proof-of-work Layer 1 Kadena blockchain.
                      </div>

                    </div>
                  </div>
                </div>
                <div className='w-3/4 lg:w-full rounded-2xl bg-gradient-to-b p-px mt-2 lg:m-2 from-[#0038FF] via-[#BD00FF] to-[#BD00FF]'>
                  <div className='"w-full h-full flex flex-col items-center py-6 px-2 rounded-2xl bg-white'>
                  {/* <div className='flex flex-row items-center w-full'> */}
                    <div className=''>
                      <ESC className="" />
                    </div>
                    <div className='w-full'>
                      <span className='text-sky-800'>Enforceable Smart Contracts</span>
                      <div className='mt-4'>
                        Customise smart contracts to track and control how goods are traded.
                      </div>
                    </div>
                  </div>
                </div>
                <div className='w-3/4 lg:w-full rounded-2xl bg-gradient-to-b p-px mt-2 lg:m-2 from-[#0038FF] via-[#BD00FF] to-[#BD00FF]'>
                  <div className='"w-full h-full flex flex-col items-center py-6 px-2 rounded-2xl bg-white'>
                    <div className='mb-4'>
                      <DFMA className="" />
                    </div>
                    <div className='w-full mt-3'>
                      <span className='text-sky-800'>Designed for mass adoption</span>
                      <div className='mt-4'>
                        The most secure, energy efficient, and scalable plug and play Web 3.0 solution,  designed for a Web 2.0 user base.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='text-center mt-8 text-3xl text-sky-800'>
                Easy, peasy, Web 3.0 ready.
              </div>
            </div>
          </section>

          <section className=" bg-white z-0">
                <div className='font-medium text-4xl text-black leading-tight justify-center text-center mt-24 lg:mt-36'>
                What is a <span className='font-bold'>Native Asset</span>?
                </div>
            <div className="flex flex-col justify-center items-center lg:flex-row mt-12">
              <div className='w-11/12 lg:w-1/2'>
                <div className='text-[17px] justify-center text-justify'>

                  Native Assets are unique, tamper-proof digital records directly created by the product’s originator, serving as both a digital passport and a certificate of authenticity. <br /><br /> They differ from NFTs, functioning as utility tokens that track an object’s lifecycle, ownership changes, and servicing history, ensuring each item’s uniqueness and verifiable authenticity.

                </div>
              </div>
              <div className="w-11/12 lg:w-1/2 min-w-[60%] text-end pt-6 lg:pt-0 px-8">
                <Image
                  src={SteveOlsonBeautifulExplotionCertPhone}
                  alt="SteveOlsonBeautifulExplotionCertPhone"
                />
              </div>
            </div>
            <div className='text-3xl text-sky-800 text-center mt-4 lg:mt-2'>
              Protect and connect.
            </div>
          </section>

          <section className="z-10 text-black text-center mt-24 lg:mt-36" ref={buildSectionRef}>
            <div>
            <div className='flex flex-row items-center justify-center font-medium text-4xl text-black leading-tight'>
                <div>
                  How
                </div>
                <div className='mt-px ml-2 w-[100px] lg:w-[122px] h-auto md:w-[120px] mr-4 md:mr-0'>
                  <DnaLogoWithoutText height={25} width={122} viewBox={'0 0 100 16'}/>
                </div>
                <div>
                  works?
                </div>
              </div>
              <div className='text-[17px] justify-center text-center items-center mt-8'>
                Create a direct link to your customers for marketing, community building, reliable secondary markets and closed loop recycling schemes, linked to their asset.

              </div>
            </div>
            <div className="container-landing">
              <div className="text-center lg:my-8 flex flex-col lg:flex-row justify-center items-center lg:items-stretch font-poppins">
                <div className='w-3/4 lg:w-full rounded-2xl bg-gradient-to-b p-px mt-12 mb-2 lg:m-0 from-[#0038FF] via-[#BD00FF] to-[#BD00FF]'>
                  <div className="w-full h-full flex flex-col items-center py-6 px-2 rounded-2xl bg-white">
                    <SecureSVG className="mb-10" />
                    <h3 className="text-xl font-light text-sky-800">Secure</h3>
                    <p className="mt-8 text-[13px] font-light font-poppins">Set Smart Contract policies (transferability, royalties & burning). <br /><br />
                      Connect to loT devices / Scans / NFCs or other recognition technologies to meet extra security needs.</p>
                  </div>
                </div>

                <div className='lg:w-12'></div>

                <div className='w-3/4 lg:w-full rounded-2xl bg-gradient-to-b p-px m-2 lg:m-0 from-[#0038FF] via-[#BD00FF] to-[#BD00FF]'>
                  <div className="w-full h-full flex flex-col items-center py-6 px-2 rounded-2xl bg-white">
                  <MintSVG className="mb-10" />
                    <h3 className="text-xl font-light text-sky-800">Mint </h3>
                    <p className="mt-8 text-[13px] font-light">Plug into the DNA&apos;s platform via an API for automated batch tokenisation.</p>
                  </div>
                </div>

                <div className='w-12'></div>

                <div className='w-3/4 lg:w-full rounded-2xl bg-gradient-to-b p-px m-2 lg:m-0 from-[#0038FF] via-[#BD00FF] to-[#BD00FF]'>
                  <div className="w-full h-full flex flex-col items-center py-6 px-2 rounded-2xl bg-white">
                    <InitialSaleSVG className="mb-10" />
                    <h3 className="text-xl font-light text-sky-800">Initial Sale</h3>
                    <p className="mt-8 text-[13px] font-light">Activation via email, NFC or secure QR code. <br /><br /> Link token to a specific customer email account.</p>
                  </div>
                </div>

                <div className='w-12'></div>

                <div className='w-3/4 lg:w-full rounded-2xl bg-gradient-to-b p-px m-2 lg:m-0 from-[#0038FF] via-[#BD00FF] to-[#BD00FF]'>
                  <div className="w-full h-full flex flex-col items-center py-6 px-2 rounded-2xl bg-white">
                    <MarketplaceSVG className="mb-10" />
                    <h3 className="text-xl font-light text-sky-800">Marketplace 3.0</h3>
                    <p className="mt-8 text-[13px] font-light">Remove bad actors. <br /><br /> Log servicing. <br /><br /> Facilitate end of life disposal.</p>
                  </div>
                </div>

                <div className='w-12'></div>

                <div className='w-3/4 lg:w-full rounded-2xl bg-gradient-to-b p-px mt-2 lg:m-0 from-[#0038FF] via-[#BD00FF] to-[#BD00FF]'>
                <div className="w-full h-full flex flex-col items-center py-6 px-2 rounded-2xl bg-white">
                  <ResellSVG className="mb-10" />
                  <h3 className="text-xl font-light text-sky-800">Community</h3>
                  <p className="mt-8 text-[13px] font-light">Promote loyalty, trust and connectivity to grow your community. <br /><br />  Create targeted reward schemes. <br /><br /> Secure secondary and private marketplaces.</p>
                </div>
                </div>

              </div>
            </div>
            <div className='text-3xl text-sky-800 text-center mt-12 mb-8'>
              Simple, secure, scalable.
            </div>
          </section>

          <section className="relative text-center bg-white" ref={supportSectionRef} id="support">
            <div className="container-landing mb-36 flex flex-col items-center">
              <div className="justify-center w-full lg:w-3/5">
                <Image
                  src={JwBlockchain}
                  alt="JwBlockchain"
                />
              </div>
              <div className='flex -top-16 text-4xl lg:text-5xl font-medium leading-tight tracking-normal text-black'>
              Let’s mint the future together.
              </div>
              <div className='pt-12 lg:w-1/6'>
                <a href="mailto:info@thedna.tech" className=" text-center px-6 py-4 rounded-full bg-black text-white text-xs">Ask for a demo</a>
              </div>
            </div>
          </section>
        </main>
        <Footer />
      </div>
    </>
  )
}
