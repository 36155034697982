/* eslint-disable react/prop-types */
import { React } from 'react'
import Head from 'next/head'

export default function PageTitle (props) {
  const { title } = props
  return (
    <>
      <Head>
        <title>{`DNA - Database of Native Assets | ${title}`}</title>
      </Head>
    </>
  )
}
